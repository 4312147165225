import React from "react"

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 98 98",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path
        d="M350 856 c-184 -52 -319 -226 -320 -411 -1 -342 394 -545 673 -345
        259 186 226 581 -60 724 -90 46 -200 58 -293 32z m273 -53 c82 -39 154 -110
        193 -190 48 -98 43 -285 -9 -357 -13 -18 -17 -13 -56 58 l-43 77 1 153 c1 84
        -1 160 -5 169 -5 15 -30 17 -245 17 l-239 0 0 -161 0 -162 -47 -88 c-47 -88
        -48 -89 -64 -67 -59 77 -65 249 -13 358 71 150 203 231 369 227 78 -2 99 -6
        158 -34z m60 -251 c2 -135 1 -143 -18 -148 -11 -2 -110 -3 -220 -2 l-200 3 -3
        148 -3 147 221 -2 221 -3 2 -143z m44 -239 l35 -63 -302 0 -302 0 25 43 c13
        23 30 52 36 65 l12 22 230 -2 231 -3 35 -62z m43 -112 c-16 -31 -90 -90 -149
        -118 -71 -35 -200 -44 -283 -19 -61 17 -163 87 -191 129 l-17 27 325 0 c318 0
        325 0 315 -19z"
      />
      <path
        d="M435 658 c-11 -6 -23 -16 -27 -22 -12 -17 -9 -61 3 -73 9 -9 5 -18
        -15 -35 -14 -13 -29 -38 -32 -55 l-6 -33 101 0 101 0 0 26 c0 15 -12 38 -29
        55 -27 27 -29 31 -16 55 14 27 10 57 -9 72 -21 17 -51 21 -71 10z m63 -46 c4
        -28 -14 -52 -38 -52 -26 0 -43 26 -36 55 6 22 11 26 39 23 25 -2 33 -8 35 -26z
        m17 -107 c14 -13 25 -29 25 -35 0 -13 -147 -14 -155 0 -3 5 5 21 19 35 33 33
        79 33 111 0z"
      />
      <path
        d="M270 340 c0 -6 72 -10 195 -10 123 0 195 4 195 10 0 6 -72 10 -195
        10 -123 0 -195 -4 -195 -10z"
      />
      <path d="M420 296 c0 -12 11 -16 46 -16 59 0 55 24 -4 28 -33 2 -42 0 -42 -12z" />
    </g>
  </svg>
)
export default SVG
