import React from "react"

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 98 98",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path
        d="M350 856 c-184 -52 -319 -226 -320 -411 -1 -342 394 -545 673 -345
        259 186 226 581 -60 724 -90 46 -200 58 -293 32z m273 -53 c82 -39 154 -110
        194 -190 26 -53 28 -67 28 -168 0 -101 -2 -115 -29 -170 -38 -76 -114 -153
        -190 -190 -76 -37 -202 -46 -288 -21 -114 33 -223 142 -263 264 -27 83 -18
        199 21 282 71 150 203 231 369 227 78 -2 99 -6 158 -34z"
      />
      <path
        d="M187 673 c-4 -3 -7 -95 -7 -203 l0 -197 98 -5 c93 -5 152 -21 152
        -41 0 -5 -7 -6 -16 -3 -9 3 -70 12 -135 18 l-119 12 0 190 c0 129 -3 187 -10
        180 -7 -7 -9 -82 -8 -205 l3 -194 152 -8 c84 -4 222 -4 305 0 l153 8 3 203 c1
        132 -1 202 -8 202 -6 0 -10 -67 -10 -188 l0 -188 -119 -12 c-65 -6 -126 -15
        -135 -18 -9 -3 -16 -2 -16 3 0 20 59 36 153 41 l97 5 0 43 c0 24 -4 44 -10 44
        -5 0 -10 -16 -10 -35 l0 -35 -68 0 c-38 0 -91 -7 -118 -15 -27 -8 -50 -15 -51
        -15 -2 0 -3 63 -3 140 0 87 -4 140 -10 140 -6 0 -10 -53 -10 -140 0 -77 -1
        -140 -2 -140 -2 0 -25 7 -52 15 -27 8 -80 15 -118 15 l-68 0 0 185 0 185 95 0
        c88 0 98 -2 120 -25 14 -13 25 -33 25 -45 0 -31 15 -24 28 13 16 45 47 57 147
        57 l85 0 0 -140 c0 -87 4 -140 10 -140 6 0 10 57 10 150 l0 150 -100 0 c-101
        0 -150 -13 -164 -42 -5 -11 -7 -11 -12 0 -13 29 -63 42 -157 42 -51 0 -97 -3
        -100 -7z"
      />
      <path
        d="M236 601 c-5 -8 15 -11 64 -11 39 0 80 -5 92 -11 24 -13 37 -4 19 14
        -16 16 -166 23 -175 8z"
      />
      <path
        d="M503 600 c-13 -5 -23 -14 -23 -21 0 -7 7 -8 18 -3 9 4 52 9 94 12 43
        2 75 8 72 13 -7 12 -132 11 -161 -1z"
      />
      <path
        d="M241 551 c-12 -8 -10 -11 10 -15 50 -11 169 -12 166 -1 -7 21 -148
        34 -176 16z"
      />
      <path
        d="M513 549 c-54 -16 -37 -25 40 -21 96 4 126 10 106 23 -18 11 -104 10
        -146 -2z"
      />
      <path
        d="M236 491 c10 -10 184 -19 184 -10 0 13 -34 19 -113 19 -49 0 -77 -4
        -71 -9z"
      />
      <path
        d="M493 493 c-7 -2 -13 -8 -13 -12 0 -10 173 -1 184 10 10 9 -149 12
        -171 2z"
      />
      <path
        d="M236 441 c11 -11 184 -20 184 -10 0 13 -32 19 -113 19 -48 0 -77 -4
        -71 -9z"
      />
      <path
        d="M493 443 c-7 -2 -13 -8 -13 -12 0 -10 173 -1 184 10 10 9 -149 12
        -171 2z"
      />
      <path d="M240 392 c0 -9 168 -23 179 -15 2 2 1 6 -3 11 -12 11 -176 16 -176 4z" />
      <path
        d="M494 384 c-18 -15 -18 -15 6 -10 14 3 58 6 98 6 41 0 72 4 72 10 0
        17 -153 12 -176 -6z"
      />
      <path
        d="M235 340 c-4 -6 23 -10 72 -10 48 0 84 -5 91 -12 6 -6 15 -8 19 -4 5
        4 -1 14 -11 22 -23 16 -161 20 -171 4z"
      />
      <path
        d="M494 336 c-24 -18 -13 -31 14 -17 12 6 53 11 92 11 45 0 69 4 65 10
        -10 16 -148 12 -171 -4z"
      />
    </g>
  </svg>
)
export default SVG
