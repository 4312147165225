import React from "react"

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 98 98",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path
        d="M350 856 c-184 -52 -319 -226 -320 -411 -1 -342 394 -545 673 -345
        259 186 226 581 -60 724 -90 46 -200 58 -293 32z m273 -53 c82 -39 154 -110
        194 -190 26 -53 28 -67 28 -168 0 -101 -2 -115 -29 -170 -38 -76 -114 -153
        -190 -190 -76 -37 -202 -46 -288 -21 -114 33 -223 142 -263 264 -27 83 -18
        199 21 282 71 150 203 231 369 227 78 -2 99 -6 158 -34z"
      />
      <path
        d="M363 776 c-104 -34 -176 -98 -222 -196 -21 -45 -26 -70 -26 -135 0
        -102 34 -183 106 -250 73 -68 126 -88 234 -89 79 -1 97 2 146 27 67 32 142
        109 172 175 31 66 30 206 -1 275 -67 149 -262 241 -409 193z m81 -75 l1 -61
        -45 0 c-74 0 -76 9 -19 74 28 31 54 55 56 52 3 -3 6 -32 7 -65z m-80 26 c-16
        -18 -36 -43 -44 -55 -14 -20 -19 -21 -53 -11 -34 9 -37 12 -25 26 13 16 124
        73 142 73 4 0 -5 -15 -20 -33z m206 -78 c0 -5 -22 -9 -50 -9 l-50 0 0 63 1 62
        49 -53 c28 -29 50 -58 50 -63z m1 96 c52 -19 114 -64 102 -75 -4 -5 -24 -11
        -45 -15 -31 -6 -38 -4 -48 17 -7 12 -26 38 -43 56 -35 36 -29 39 34 17z m-288
        -114 c4 -4 3 -21 -4 -37 -6 -16 -14 -51 -18 -79 l-6 -50 -62 -3 -63 -3 6 33
        c3 18 19 62 35 97 l29 63 37 -7 c21 -3 42 -10 46 -14z m458 -41 c15 -30 30
        -72 34 -93 l6 -38 -68 3 -68 3 -6 50 c-4 28 -12 63 -18 79 -14 34 -15 32 29
        44 54 16 62 11 91 -48z m-307 26 c4 -1 8 -35 8 -76 l0 -75 -82 -3 -83 -3 6 49
        c3 28 12 65 20 85 15 34 15 34 68 30 30 -3 58 -6 63 -7z m170 -43 c9 -26 16
        -62 16 -80 l0 -33 -75 0 -75 0 0 74 c0 83 1 84 72 85 l46 1 16 -47z m-344
        -156 c0 -13 9 -50 20 -82 23 -66 21 -70 -43 -81 -31 -5 -41 -2 -52 13 -15 19
        -40 88 -50 141 l-7 32 66 0 c63 0 66 -1 66 -23z m182 -57 l0 -75 -59 -3 -60
        -3 -17 45 c-9 24 -20 61 -23 81 l-6 36 83 -3 82 -3 0 -75z m175 44 c-3 -20
        -14 -56 -23 -80 l-17 -44 -53 0 -54 0 0 80 0 80 76 0 77 0 -6 -36z m158 -6
        c-9 -61 -53 -148 -73 -148 -21 1 -92 17 -98 22 -2 3 5 31 16 63 11 32 20 69
        20 82 0 22 3 23 71 23 l71 0 -7 -42z m-330 -203 c0 -36 -3 -65 -7 -65 -10 0
        -88 105 -88 118 0 8 18 12 48 12 l47 0 0 -65z m105 54 c0 -11 -68 -109 -76
        -109 -2 0 -4 27 -4 60 l0 60 40 0 c23 0 40 -5 40 -11z m-192 -59 c34 -48 39
        -60 25 -60 -27 1 -94 34 -132 65 l-34 29 34 12 c55 20 63 17 107 -46z m288 49
        c44 -11 44 -12 27 -31 -10 -11 -36 -30 -58 -43 -41 -24 -119 -49 -113 -37 12
        26 83 122 90 122 5 0 29 -5 54 -11z"
      />
    </g>
  </svg>
)
export default SVG
